@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.clients {
  padding: 0 0 functions.vw_d(146);
  background-color: var(--color-bg-dark);

  @include mixins.screen('mobile') {
    padding: functions.vw(0) 0 functions.vw(95);
  }
}

.listWrapper {
  position: relative;
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    background-image: linear-gradient(to right, black 0%, rgba(black, 0) 20%, rgba(black, 0) 80%, black 100%);
    z-index: 2;
  }
}

.list {
  animation: scroll 90s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes scroll {
  to {
    transform: translateX(-50%);
  }
}

.listItems {
  min-width: 100vw;
  grid-auto-flow: column;
  column-gap: functions.vw_d(60);
  margin-left: functions.vw_d(60);

  // img {
  //   margin-right: functions.vw_d(40);
  // }

  @include mixins.screen('mobile') {
    position: relative;
    min-width: unset;
    column-gap: functions.vw(40);
    margin-left: functions.vw(40);

    img {
      // margin-right: functions.vw(40);
      max-height: functions.vw(26);
    }
  }
}
