@use 'src/styles/mixins';
@use 'src/styles/functions';

.collaboration {
  position: relative;
  overflow-x: hidden;
  padding-top: functions.vw_d(155);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(90);
  }
}

.pageTitle {
  font-size: functions.vw_d(64);
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.12;
  letter-spacing: -0.36px;
  font-weight: 600;
  width: functions.vw_d(949);
  margin: 0 auto;
  padding: 0 0 functions.vw_d(70);

  @include mixins.screen('mobile') {
    padding: 0 functions.vw(30) functions.vw(30);
    font-size: functions.vw(28);
    line-height: 1.2;
    letter-spacing: -0.35px;
    margin: 0 auto;
    width: 100%;
  }
}

.imageWrap {
  width: functions.vw_d(1120);
  margin: 0 auto;
  background-color: lightgray;
  border-radius: functions.vw_d(12);
  overflow: hidden;

  @include mixins.screen('mobile') {
    border-radius: functions.vw(10);
    margin: 0 auto functions.vw(20);
  }
}
