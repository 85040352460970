@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.root {
  position: relative;
  overflow-x: hidden;
  font-size: functions.vw_d(22);
  line-height: functions.vw_d(32);
  margin-bottom: functions.vw_d(64);

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
    line-height: 1.4;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: themes.$green-primary;
    text-decoration: underline;
    transition: 200ms ease;
    white-space: nowrap;

    &:hover {
      opacity: 0.8;
    }

    &:visited {
      color: themes.$green-dark;
    }
  }

  p {
    margin-bottom: functions.vw_d(12);

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(12);
    }
  }

  ol {
    list-style-type: none;

    & > li {
      margin-top: functions.vw_d(32);
      margin-bottom: functions.vw_d(32);

      @include mixins.screen('mobile') {
        margin-top: 0;
        margin-bottom: functions.vw(18);
      }
    }
  }

  ol.withCounters,
  ol.withCounters ol {
    counter-reset: item;

    & li {
      position: relative;
      display: block;

      &::before {
        position: absolute;
        top: 0;
        right: 100%;
        transform: translateX(functions.vw_d(-12));
        content: counters(item, '.') ' ';
        counter-increment: item;
      }
    }

    .letters {
      z-index: 1;
      list-style-type: lower-latin;
      padding-left: functions.vw_d(24);

      li {
        display: list-item;
      }

      li::before {
        display: none;
      }
    }
  }

  ol.withCounters > li::before {
    visibility: hidden;
  }

  ol ol {
    list-style-type: lower-latin;
    padding-left: functions.vw_d(58);

    @include mixins.screen('mobile') {
      padding-left: functions.vw(18);
    }

    & > li {
      margin-top: 0;
      margin-bottom: functions.vw_d(16);
    }
  }

  ol ul {
    list-style-type: circle;
    padding-left: functions.vw_d(24);
    padding-top: functions.vw_d(16);

    @include mixins.screen('mobile') {
      padding-left: functions.vw(6);
    }

    li {
      margin-bottom: functions.vw_d(12);
    }
  }
}

.t1,
.t2,
.t3 {
  font-weight: 600;
}

.t1 {
  font-size: functions.vw_d(64);
  line-height: functions.vw_d(72);
  margin-bottom: functions.vw_d(65);

  @include mixins.screen('mobile') {
    font-size: functions.vw(26);
    line-height: 1.4;
    margin-bottom: functions.vw(32);
  }
}

.t2 {
  font-size: functions.vw_d(32);
  line-height: functions.vw_d(40);
  margin-bottom: functions.vw_d(22);

  @include mixins.screen('mobile') {
    font-size: functions.vw(18);
    line-height: 1.4;
  }
}

.t3 {
  margin-bottom: functions.vw_d(8);
}

.a1 {
  text-decoration: underline;
}
