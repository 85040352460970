/*
  This file is used to define global css variables that can be used in the entire project.
  You can define colors, fonts, sizes, etc. here.
  You can also define variables for dark mode and light mode.

  Do not import this file in any other file. It is automatically imported in the base.scss file.
 */

:root {
  --color-bg-dark: rgb(0, 0, 0);
  --color-bg-light: rgb(255, 255, 255);
}
