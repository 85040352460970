@use 'src/styles/mixins';
@use 'src/styles/functions';

$green-primary: #00a87e;

.dropdownIcon {
  position: absolute;
  color: currentColor;

  .menuDesktop & {
    width: functions.vw_d(7);
    top: functions.vw_d(5);
    right: functions.vw_d(20);
  }

  .dropdownMenu & {
    right: 0;
    top: functions.vw(14);
    width: functions.vw(12);
    transform: rotate(-90deg);
  }

  .isOpen & {
    transform: none;
  }

  path {
    fill: currentColor;
  }
}

.dropdownMenu {
  will-change: height;
}

.dropdownMenu.isOpen {
  transform: translateZ(0);
}

.dropdown {
  min-width: calc(100% + #{functions.vw_d(22)});
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: functions.vw_d(4);
  padding: functions.vw_d(24) functions.vw_d(24) 0;
  top: functions.vw_d(40);
  left: functions.vw_d(0);

  .isOpen & {
    transition-delay: 50ms;
  }

  @include mixins.screen('mobile') {
    min-width: unset;
    box-shadow: none;
    top: functions.vw(40);
    left: 0;
    padding: functions.vw(16) 0 0;
  }

  a {
    margin-bottom: functions.vw_d(24);
    transition: 200ms ease;

    &:hover {
      color: $green-primary;

      .title,
      .subtitle {
        color: $green-primary;
      }
    }

    @include mixins.screen('mobile') {
      font-size: functions.vw(22);
      margin-bottom: functions.vw(16);
      color: #868585;
    }

    &.navLinkActive {
      .title,
      .subtitle {
        color: $green-primary;
      }
    }

    .title {
      display: block;
      padding-bottom: functions.vw_d(4);
      font-size: functions.vw_d(13);
      line-height: 1.231;
      letter-spacing: functions.vw_d(-0.05);
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }

    .subtitle {
      font-size: functions.vw_d(11);
      line-height: 1.455;
      letter-spacing: functions.vw_d(-0.08);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.dropdownBig {
  min-width: calc(100% + #{functions.vw_d(22)});
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: functions.vw_d(4);
  padding: functions.vw_d(24) functions.vw_d(24) 0;
  top: functions.vw_d(40);
  left: functions.vw_d(0);

  .isOpen & {
    transition-delay: 50ms;
  }

  .column {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 functions.vw_d(24) 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: functions.vw_d(24);
      width: 1px;
      background-color: #f0f0f0;
    }
    &:first-child {
      &:after {
        display: none;
      }
    }
  }

  a {
    margin-bottom: functions.vw_d(24);
    transition: 200ms ease;

    &:hover {
      color: $green-primary;

      .title,
      .subtitle {
        color: $green-primary;
      }
    }

    &.navLinkActive {
      .title,
      .subtitle {
        color: $green-primary;
      }
    }

    .title {
      display: block;
      padding-bottom: functions.vw_d(4);
      font-size: functions.vw_d(13);
      line-height: 1.231;
      letter-spacing: functions.vw_d(-0.05);
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }

    .subtitle {
      font-size: functions.vw_d(11);
      line-height: 1.455;
      letter-spacing: functions.vw_d(-0.08);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
