@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.solutions {
  padding: functions.vw_d(84) functions.vw_d(60) functions.vw_d(115) functions.vw_d(126);

  h3 {
    font-size: functions.vw_d(64);
    line-height: 1.125;
    margin-bottom: functions.vw_d(86);
  }

  @include mixins.screen('mobile') {
    display: block;
    padding: functions.vw(55) functions.vw(30);

    h3 {
      font-size: functions.vw(26);
      margin-bottom: functions.vw(50);
      text-align: center;

      br {
        display: none;
      }
    }
  }
}

.menu {
  button {
    position: relative;
    font-size: functions.vw_d(26);
    max-width: functions.vw_d(220);
    line-height: 1.24;
    padding-bottom: functions.vh_d(40);
    transition: 150ms ease;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: functions.vw_d(28);
      height: 2px;
      display: block;
      width: functions.vw_d(68);
      background-color: themes.$green-primary;
      transition: 150ms ease-in;
      transform-origin: left center;
    }

    &:not(:hover):not(.active)::after {
      transform: scaleX(0);
    }
  }

  .active {
    color: themes.$green-primary;
  }
}

.headIcon {
  width: auto;
  height: functions.vw_d(62);
  margin-bottom: functions.vw_d(36);

  @include mixins.screen('mobile') {
    height: functions.vw(40);
    margin: 0 auto functions.vw(24);
  }
}

.content {
  font-size: functions.vw_d(16);
  grid-gap: functions.vw_d(66);

  @include mixins.screen('mobile') {
    display: block;
    font-size: functions.vw(16);
    padding-left: 0;
  }
}

.contentItem {
  @include mixins.screen('mobile') {
    &:not(:last-of-type) {
      margin-bottom: functions.vw(60);
    }

    h4,
    h4 + p {
      text-align: center;
    }
  }

  h4 {
    font-size: functions.vw_d(32);
    line-height: 1.375;
    margin-bottom: functions.vw_d(28);
    min-height: functions.vw_d(88);

    @include mixins.screen('mobile') {
      font-size: functions.vw(22);
      margin-bottom: functions.vw(26);
      min-height: unset;

      br {
        display: none;
      }
    }
  }

  h4 + p {
    margin-bottom: functions.vw_d(32);
    font-size: functions.vw_d(16);
    padding-right: functions.vw_d(38);
    line-height: 1.3;
    min-height: functions.vw_d(104);

    @include mixins.screen('mobile') {
      font-size: functions.vw(16);
      margin-bottom: functions.vw(14);
      min-height: unset;
    }
  }

  a {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: themes.$green-primary;
    transition: 200ms ease;

    svg {
      margin-left: functions.vw_d(8);
    }

    &:hover {
      color: themes.$green-dark;
    }

    @include mixins.screen('mobile') {
      justify-content: center;

      svg {
        margin-left: functions.vw(8);
      }
    }
  }
}

.itemHead {
  position: relative;
}
