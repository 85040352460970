@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.newsArticleWrapper {
  width: 43%;
  max-width: functions.vw_d(600);
  padding-bottom: functions.vw_d(80);

  &:nth-child(2n + 1) {
    margin-left: 0;
  }

  @include mixins.screen('mobile') {
    max-width: inherit;
    padding-bottom: functions.vw(50);
    width: 100%;
  }

  &:last-child {
    @include mixins.screen('mobile') {
      padding-bottom: (0);
    }
  }

  .site {
    padding-bottom: functions.vw_d(22);
    font-size: functions.vw_d(24);
    line-height: functions.vw_d(28);
    text-align: left;
    font-weight: 600;
    text-decoration: underline;
    color: themes.$green-primary;

    @include mixins.screen('mobile') {
      padding-bottom: functions.vw(7);
      font-size: functions.vw(18);
      line-height: 1.4;
    }
  }

  .title {
    padding-bottom: functions.vw_d(20);
    font-size: functions.vw_d(18);
    line-height: functions.vw_d(18);
    text-align: left;
    color: #000;
    font-weight: 600;

    @include mixins.screen('mobile') {
      padding-bottom: functions.vw(11.5);
      font-size: functions.vw(16);
      line-height: 1.4;
    }
  }

  .description {
    font-size: functions.vw_d(18);
    line-height: functions.vw_d(24);
    text-align: left;
    font-weight: 300;

    @include mixins.screen('mobile') {
      font-size: functions.vw(16);
      line-height: 1.4;
    }
  }
}
