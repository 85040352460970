@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.headlineSection {
  // margin-top: vw_d(100);
  background: black;
  color: #fff;
  .title {
    font-size: functions.vw_d(64);
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    line-height: 1.12;
    letter-spacing: -0.36px;
    font-weight: 600;
    width: functions.vw_d(1077);
    margin: 0 auto;
    padding: 0 0 functions.vw_d(70);

    @include mixins.screen('mobile') {
      font-size: functions.vw(28);
      line-height: 1.2;
      letter-spacing: -0.35px;
      margin: 0 auto;
      width: 100%;
      padding: 0 0 functions.vw(30);
    }
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1200px) {
      height: functions.vw_d(720);
    }

    @include mixins.screen('mobile') {
      height: functions.vw(270);
    }

    .tabletBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    video {
      z-index: 3;
      background-size: cover;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      width: auto;
      height: auto;

      @include mixins.screen('mobile') {
        width: 100%;
        transform: translateY(30px) scale(1.6);
      }

      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }

  .videoMuteCursor {
    &:hover {
      cursor: url('../../../../../assets/icons/muteCursor.svg'), auto;
    }
  }

  .videoUnmuteCursor {
    &:hover {
      cursor: url('../../../../../assets/icons/unmuteCursor.svg'), auto;
    }
  }
  .muteButton {
    position: absolute;
    right: functions.vw_d(35);
    bottom: functions.vw_d(75);
    z-index: 30;

    @include mixins.screen('mobile') {
      bottom: functions.vw(14);
      right: functions.vw(14);
    }

    img {
      @include mixins.screen('mobile') {
        width: functions.vw(45);
      }
    }
  }

  .videoWrapper {
    position: relative;
    margin-bottom: functions.vw_d(109);

    .tabletBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .tabletShadow {
      position: absolute;
      bottom: -20px;
      width: 80%;
      height: 1px;
      border-radius: 40%;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 5px 27px 29px 4px rgba(0, 0, 0, 0.7);
    }
  }

  .content {
    margin-bottom: functions.vw_d(74);
    padding-top: functions.vw_d(30);
    width: functions.vw_d(1117);

    h3 {
      font-size: functions.vw_d(32);
      line-height: functions.vw_d(44);
      letter-spacing: -0.35px;
      width: functions.vw_d(429);
      margin-right: functions.vw_d(145);
    }

    p {
      font-size: functions.vw_d(22);
      line-height: functions.vw_d(32);
      letter-spacing: -0.29px;
    }
  }
}

.shadow,
.shadow2 {
  position: absolute;
  width: 100%;
  height: functions.vw_d(90);
  z-index: 4;
  left: 0;
  pointer-events: none;

  @include mixins.screen('mobile') {
    height: functions.vw(50);
  }
}

.shadow {
  background: linear-gradient(transparent, #000);
  bottom: functions.vw_d(0);
  z-index: 4;

  @include mixins.screen('mobile') {
    bottom: functions.vw(0);
  }
}

.shadow2 {
  background: linear-gradient(#000, transparent);
  top: 0;
}

@media (max-width: #{themes.$media-mobile-max}px) {
  .headlineSection {
    padding-top: functions.vw(60);

    video {
      width: 100%;
    }

    .content {
      margin-top: functions.vw(9);
      margin-bottom: functions.vw(56);
      width: functions.vw(320);

      h3 {
        font-size: functions.vw(22);
        line-height: functions.vw(28);
        letter-spacing: -0.29px;
        width: functions.vw(288);
        margin-right: 0;
        margin-bottom: functions.vw(12);
      }

      p {
        font-size: functions.vw(16);
        line-height: functions.vw(24);
        letter-spacing: -0.18px;
      }
    }
  }
}
