@use 'src/styles/mixins';
@use 'src/styles/functions';

.contentLayout {
  width: 100%;
}

.gilPage {
  .firstSection {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    margin-bottom: functions.vw_d(60);

    @include mixins.screen('mobile') {
      display: flex;
      flex-direction: column;
    }

    .topImage {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: functions.vw_d(80);

      @include mixins.screen('mobile') {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-bottom: functions.vw(20);
      }
      .smallGil {
        width: functions.vw_d(150);
        height: functions.vw_d(150);
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: functions.vw_d(13);

        @include mixins.screen('mobile') {
          width: functions.vw(150);
          height: functions.vw(150);
          margin-right: functions.vw(50);
        }
      }

      .topImageTexts {
        position: relative;
        margin-left: functions.vw_d(40);

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: #000;
          left: functions.vw_d(-11);

          @include mixins.screen('mobile') {
            left: functions.vw(-11);
          }
        }
        h1 {
          font-size: functions.vw_d(16);
          font-weight: 600;

          @include mixins.screen('mobile') {
            font-size: functions.vw(16);
          }
        }

        p {
          font-size: functions.vw_d(14);
          @include mixins.screen('mobile') {
            font-size: functions.vw(14);
          }
        }
      }
    }

    .topTextBlock {
      width: functions.vw_d(412);

      @include mixins.screen('mobile') {
        width: 100%;
      }
    }
  }

  .bigGil {
    width: 100%;
    object-fit: cover;
    margin-bottom: functions.vw_d(60);

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(30);
      margin-top: functions.vw(30);
    }
  }
}

.secondSection {
  margin: auto;
  width: functions.vw_d(550);
  margin-bottom: functions.vw_d(60);

  @include mixins.screen('mobile') {
    width: 100%;
  }
}

.title {
  font-size: functions.vw_d(20);
  font-weight: 600;
  margin-bottom: functions.vw_d(26);

  @include mixins.screen('mobile') {
    font-size: functions.vw(20);
    margin-bottom: functions.vw(35);
  }
}

.subtitle {
  font-size: functions.vw_d(16);

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
  }
}
