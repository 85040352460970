@use 'sass:math';
@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.portfolio {
  padding: functions.vw_d(100) 0 functions.vw_d(91.5);

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0 functions.vw(60);
  }
}

.portfolioHeader {
  padding: 0 functions.vw_d(30);

  @include mixins.screen('mobile') {
    width: functions.vw(320);
    margin: 0 auto;
    padding: 0 0 functions.vw(60) 0;
  }

  h3 {
    font-size: functions.vw_d(94);
    line-height: 1.17;
    letter-spacing: functions.vw_d(-1);
    color: rgba(0, 0, 0, 0.87);
    margin: 0 0 functions.vw_d(40);

    @include mixins.screen('mobile') {
      margin: 0 0 functions.vw(24);
      font-size: functions.vw(38);
      line-height: 1.263;
      letter-spacing: functions.vw(-0.48);
    }
  }

  p {
    width: functions.vw_d(945);
    max-width: 100%;
    margin: 0 auto;
    font-size: functions.vw_d(26);
    line-height: 1.385;
    letter-spacing: functions.vw_d(-0.33);
    color: rgba(0, 0, 0, 0.87);

    @include mixins.screen('mobile') {
      width: 100%;
      font-size: functions.vw(16);
      line-height: 1.5;
      letter-spacing: functions.vw(-0.18);
    }
  }

  .inspireButton {
    height: functions.vw_d(52);
    width: functions.vw_d(196);
    margin: functions.vw_d(26) auto functions.vw_d(15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: functions.vw_d(22);
    font-weight: 600;
    letter-spacing: functions.vw_d(-0.18);
    color: rgba(0, 0, 0, 0.87);
    border: solid 1px rgba(0, 0, 0, 0.38);
    border-radius: functions.vw_d(29);
    transition: all ease 0.2s;

    @include mixins.screen('mobile') {
      height: functions.vw(47);
      width: functions.vw(166);
      margin: functions.vw(27) auto functions.vw(0);
      font-size: functions.vw(16);
      font-weight: 600;
      letter-spacing: functions.vw(-0.05);
      color: rgba(0, 0, 0, 0.87);
      border-radius: functions.vw(24);
    }
    &:hover {
      color: #000;
      border: solid 1px #00a87e;
    }
  }
}

.portfolioItems {
  position: relative;
  width: 170%;
  left: -35%;
  padding-top: functions.vw_d(25);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(0);
  }
}

.portfolioItem {
  flex: 0 0 auto;
  padding: functions.vw_d(20);

  @include mixins.screen('mobile') {
    padding: functions.vw(12);

    &:nth-child(6) > .portfolioItemTall {
      background-position-x: 0;
    }
  }

  $desktop-item-height: 400;
  $desktop-item-width-wide: 640;
  $desktop-item-width-tall: 224;

  $mobile-item-height: 227;
  $mobile-item-width-wide: 360;
  $mobile-item-width-tall: 125;

  &Wide,
  &Tall {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    transition: 200ms ease-out;

    &:hover {
      @include mixins.screen('desktop') {
        transform: translateY(-10px) scale(1.025);
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
      }

      a {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &Wide {
    background-color: silver;
    width: functions.vw_d($desktop-item-width-wide);
    padding-bottom: math.div($desktop-item-height, $desktop-item-width-wide) * 100%;

    @include mixins.screen('mobile') {
      width: functions.vw($mobile-item-width-wide);
      padding-bottom: math.div($mobile-item-height, $mobile-item-width-wide) * 100%;
    }
  }

  &Tall {
    background-color: silver;
    width: functions.vw_d($desktop-item-width-tall);
    padding-bottom: math.div($desktop-item-height, $desktop-item-width-tall) * 100%;

    @include mixins.screen('mobile') {
      width: functions.vw($mobile-item-width-tall);
      padding-bottom: math.div($mobile-item-height, $mobile-item-width-tall) * 100%;
    }
  }

  a {
    position: absolute;
    top: -20%;
    left: -20%;
    right: -20%;
    bottom: -20%;
    transition: 200ms ease;
    opacity: 0;
    transform: scale(0.9) translate(-5%, 5%);
    background-color: rgba(themes.$green-primary, 0.6);
  }
}
