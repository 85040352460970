@use 'src/styles/functions';

.scrollingSection {
  position: relative;
  background-color: #000;
  min-height: 100vh;

  .header {
    position: relative;
    padding: functions.vw_d(70) 0 0;
    z-index: 3;
    transition: opacity, visibility;
    transition-duration: 0.35s;
    background-color: black;
    box-shadow: 0 10px 20px 0 black;

    .title,
    .subtitle {
      position: relative;
      z-index: 2;
    }

    .title {
      font-size: functions.vw_d(64);
    }

    .subtitle {
      margin-top: functions.vw_d(24);
      font-size: functions.vw_d(22);
      width: functions.vw_d(885);
    }
  }

  .fixedContent {
    position: relative;
    height: 100vh;
  }

  .bgVideo {
    position: absolute;
    overflow: hidden;
    z-index: 0;
    top: 50%;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, -50%);

    video {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .scrollingTextWrap,
  .scrollingTextInner {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .scrollingTextWrap {
    overflow: hidden;
  }

  .scrollingTextInner {
    transform: translateY(100%);
  }

  .scrollingText {
    width: functions.vw_d(429);
    height: 100%;
    margin-left: functions.vw_d(137);
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: functions.vw_d(32);
      margin-bottom: functions.vw_d(38);
    }

    p {
      font-size: functions.vw_d(16);
    }
  }
}

:global {
  .dark {
    background: #212121;

    :local {
      .header {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
