@use 'src/styles/mixins';
@use 'src/styles/functions';

.playBtn {
  width: functions.vw_d(64);
  height: functions.vw_d(64);
  //inset: auto functions.vw_d(24) functions.vw_d(24) auto;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  border-radius: functions.vw_d(44);
  background-color: #dedede;
  transition: background-color 200ms ease;

  &::after {
    content: '';
    position: absolute;
    inset: 31% auto auto 40%;
    width: functions.vw_d(20);
    height: functions.vw_d(24);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    background-color: #000;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  @include mixins.screen('mobile') {
    width: functions.vw(32);
    height: functions.vw(32);
    //inset: auto functions.vw(11) functions.vw(11) auto;
    border-radius: functions.vw(32);

    &::after {
      width: functions.vw(10);
      height: functions.vw(12);
    }
  }
}
