@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.headlineSection {
  padding-top: functions.vw_d(143);
  background-color: var(--color-bg-dark);
  color: #fff;
  overflow-x: hidden;

  @include mixins.screen('mobile') {
    padding-top: functions.vw(114);
  }

  .textTop {
    width: functions.vw_d(1120);
    padding-bottom: functions.vw_d(100);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
      padding-bottom: functions.vw(44);
    }
    .title {
      padding-bottom: functions.vw_d(50);
      font-size: functions.vw_d(94);
      color: rgba(255, 255, 255, 0.99);
      text-align: left;
      line-height: 1.277;
      font-weight: 600;

      @include mixins.screen('mobile') {
        padding-bottom: functions.vw(27);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }

    .subtitleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .subtitle {
        width: functions.vw_d(749);
        font-size: functions.vw_d(22);
        letter-spacing: functions.vw_d(-0.29);
        color: rgb(255, 255, 255);
        text-align: left;
        line-height: 1.455;
        font-weight: 400;

        @include mixins.screen('mobile') {
          width: 100%;
          font-size: functions.vw(16);
          letter-spacing: functions.vw(-0.18);
          line-height: 1.5;
        }
      }

      .packagesBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: functions.vw_d(167);
        height: functions.vw_d(48);
        border-radius: functions.vw_d(24);
        font-size: functions.vw_d(13);
        letter-spacing: -0.05px;
        color: rgb(255, 255, 255);
        line-height: 1.231;
        font-weight: 600;
        border: 1px solid rgb(255, 255, 255);
        transition: all ease 0.2s;

        @include mixins.screen('mobile') {
          display: none;
        }

        &:hover {
          color: #000;
          background-color: rgb(255, 255, 255);
        }
      }
    }
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: functions.vw_d(623);
    width: functions.vw_d(1120);
    margin-bottom: functions.vw_d(213);

    @include mixins.screen('mobile') {
      width: 100%;
      height: functions.vw(208);
      margin-bottom: functions.vw(70);
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: functions.vw_d(564);
      background: #000;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      left: 0;
      bottom: functions.vw_d(-18);
      z-index: 4;

      @include mixins.screen('mobile') {
        height: functions.vw(208);
        bottom: functions.vw(-7);
      }
    }

    video {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }

    .cardsWrapper {
      position: absolute;
      width: functions.vw_d(414);
      left: functions.vw_d(-167);
      bottom: functions.vw_d(-51);
      z-index: 5;

      @include mixins.screen('mobile') {
        width: functions.vw(120);
        left: functions.vw(-6);
        bottom: functions.vw(1);
      }
      img {
        display: block;
        width: 100%;
      }
    }
    .integarationsWrapper {
      position: absolute;
      width: functions.vw_d(377);
      right: functions.vw_d(-101);
      bottom: functions.vw_d(-43);
      z-index: 5;

      @include mixins.screen('mobile') {
        width: functions.vw(121);
        right: functions.vw(-35);
        bottom: functions.vw(-4);
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }

  // .videoMuteCursor {
  //   &:hover {
  //     cursor: url('../../../../../assets/icons/muteCursor.svg'), auto;
  //   }
  // }

  // .videoUnmuteCursor {
  //   &:hover {
  //     cursor: url('../../../../../assets/icons/unmuteCursor.svg'), auto;
  //   }
  // }

  // .muteButton {
  //   position: absolute;
  //   right: functions.vw_d(35);
  //   bottom: functions.vw_d(75);
  //   z-index: 30;

  //   @include mixins.screen('mobile') {
  //     bottom: functions.vw(14);
  //     right: functions.vw(14);
  //   }

  //   img {
  //     @include mixins.screen('mobile') {
  //       width: functions.vw(45);
  //     }
  //   }
  // }

  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: functions.vw_d(98);
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
      margin-bottom: functions.vw(60);
    }

    h3 {
      width: functions.vw_d(482);
      font-size: functions.vw_d(44);
      line-height: 1.205;
      letter-spacing: functions.vw_d(-0.25);

      @include mixins.screen('mobile') {
        width: 100%;
        padding-bottom: functions.vw(25);
        font-size: functions.vw(26);
        line-height: 1.385;
        letter-spacing: functions.vw(-0.33);
      }
    }

    p {
      width: functions.vw_d(548);
      font-size: functions.vw_d(22);
      line-height: 1.455;
      letter-spacing: functions.vw_d(-0.29);
      font-weight: 400;

      @include mixins.screen('mobile') {
        width: 100%;
        font-size: functions.vw(16);
        line-height: 1.5;
        letter-spacing: functions.vw(-0.2);
      }
    }
  }
}
