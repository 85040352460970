@use 'sass:math';
@use 'src/styles/functions';

.scrollingSectionMobile {
  background-color: rgba(0, 0, 0, 0.87);
  padding: functions.vw(45) 0 functions.vw(59);
  background-position: top center;
  color: rgba(255, 255, 255, 0.99);
  background-size: 100%;
  overflow: hidden;

  p {
    font-size: functions.vw(16);
    letter-spacing: -0.18px;
    line-height: functions.vw(24);
    font-weight: 300;
    font-style: normal;
    padding: 0 functions.vw(38) functions.vw(46);
  }

  .scrollingText {
    &:not(:last-child) .video {
      padding-bottom: functions.vw(62);
    }

    &:last-child video {
      box-shadow: 0 functions.vw(math.div(59, 2)) functions.vw(59) 0 black;
    }

    &.embed {
      .image {
        padding: 0 functions.vw(17) functions.vw(71);
      }
    }

    &.segments {
      .image {
        padding: 0 functions.vw(13);
      }
    }

    h3 {
      font-size: functions.vw(22);
      letter-spacing: -0.29px;
      line-height: functions.vw(28);
      padding: 0 functions.vw(40) functions.vw(18);
    }
  }
}
