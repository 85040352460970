@use 'src/styles/mixins';

.analyticsContent {
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.87);

  @include mixins.screen('mobile') {
    width: 100%;
  }

  .analyticsImage {
    width: 100%;
  }
}
