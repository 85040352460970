@use 'vars';
@use 'mixins';
@use 'functions';

body {
  background: var(--color-bg-light);
}

.text-1 {
  font-size: functions.vw_d(16);
  line-height: 1.45;

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
  }
}

.text-2 {
  font-size: functions.vw_d(22);
  line-height: 1.45;

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
  }
}

.text-3 {
  font-size: functions.vw_d(26);
  line-height: 1.25;

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
    line-height: 1.45;
  }
}

.text-4 {
  font-size: functions.vw_d(32);
  line-height: 1.375;

  @include mixins.screen('mobile') {
    font-size: functions.vw(22);
    line-height: 1.28;
  }
}

.text-5 {
  font-size: functions.vw_d(64);
  line-height: 1.125;

  @include mixins.screen('mobile') {
    font-size: functions.vw(32);
    line-height: 1.3125;
  }
}

/* Hide the Safari play button overlay */
video::-webkit-media-controls-panel,
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
  -webkit-mask-image: none !important;
}
