@use 'src/styles/mixins';
@use 'src/styles/functions';

.noCodeSolutions {
  padding: functions.vw_d(60) 0 functions.vw_d(58);

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0;
  }
  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }
    .title {
      padding-bottom: functions.vw_d(80);
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-0.53);
      line-height: 1.277;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;

      @include mixins.screen('mobile') {
        padding-bottom: functions.vw(70);
        font-size: functions.vw(32);
        letter-spacing: functions.vw(-0.48);
        line-height: 1.263;
        text-align: center;
      }
    }

    .items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: functions.vw_d(932);

      @include mixins.screen('mobile') {
        width: 100%;
        flex-wrap: wrap;
      }

      .item {
        position: relative;
        width: functions.vw_d(426);
        min-height: functions.vw_d(196);
        padding-left: functions.vw_d(30);
        margin-bottom: functions.vw_d(77);

        @include mixins.screen('mobile') {
          width: 100%;
          min-height: inherit;
          padding-left: 0;
          margin-bottom: functions.vw(60);

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: functions.vw_d(6.3);
          height: functions.vw_d(221);
          background-color: rgb(39, 196, 157);

          @include mixins.screen('mobile') {
            display: none;
          }
        }

        .itemTitle {
          height: auto;
          min-height: functions.vw_d(80);
          margin-bottom: functions.vw_d(24);
          font-size: functions.vw_d(32);
          line-height: 1.25;
          letter-spacing: functions.vw_d(-0.35);
          color: rgba(0, 0, 0, 0.87);
          font-weight: 600;

          @include mixins.screen('mobile') {
            position: relative;
            min-height: inherit;
            margin-bottom: functions.vw(30);
            font-size: functions.vw(26);
            line-height: 1.538;
            letter-spacing: functions.vw(-0.28);
            text-align: center;

            &::after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: functions.vw(-16);
              transform: translate(-50%, 0);
              width: functions.vw(69);
              height: functions.vw(2);
              background-color: rgb(39, 196, 157);
            }

            br {
              display: none;
            }
          }
        }

        p {
          font-size: functions.vw_d(16);
          line-height: 1.5;
          letter-spacing: functions.vw_d(-0.18);
          color: rgba(0, 0, 0, 0.87);

          @include mixins.screen('mobile') {
            display: block;
            padding: 0 functions.vw(11);
            font-size: functions.vw(16);
            line-height: 1.5;
            letter-spacing: functions.vw(-0.18);
            text-align: center;
          }
        }
      }
    }
  }
}
