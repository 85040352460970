@use 'sass:map';
@use 'themes';

@mixin screen($size) {
  $mediaQueries: (
    'mobile': themes.$media-mobile,
    'mobile-portrait': themes.$media-mobile-portrait,
    'mobile-landscape': themes.$media-mobile-landscape,
    'tablet': themes.$media-tablet,
    'tablet-portrait': themes.$media-tablet-portrait,
    'tablet-landscape': themes.$media-tablet-landscape,
    'desktop': themes.$media-desktop,
    'desktop-hd': themes.$media-desktop-hd,
    'non-mobile': themes.$media-non-mobile,
    'non-desktop': themes.$media-non-desktop,
  );

  // Use predefined media query
  @each $key, $value in $mediaQueries {
    @if $key == $size {
      @media only screen and #{$value} {
        @content;
      }
    }
  }

  // Use custom media query
  @if map.has-key($mediaQueries, $size) != true {
    @media only screen and #{$size} {
      @content;
    }
  }
}
