@use 'src/styles/mixins';
@use 'src/styles/functions';

.items {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: functions.vw_d(90);
  left: 0;
  width: 100%;
  height: functions.vw_d(190);
  padding: functions.vw_d(49) functions.vw_d(150);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.02);
  border-top: #f3f3f3;
  transition: 150ms ease-in;
  margin-bottom: functions.vw_d(49);
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 39; // under page <header>

  @include mixins.screen('mobile') {
    top: functions.vw(61);
    padding-left: functions.vw(10);
    padding-right: functions.vw(10);
  }

  canvas {
    transition: 0.6s;
    max-width: functions.vw_d(150);

    @include mixins.screen('mobile') {
      max-height: functions.vw(27);
    }
  }

  .fullSwiperSlide {
    width: functions.vw(80);
  }

  .item {
    border-right: 1px solid #f3f3f3;

    &:last-child {
      border-right: none;
    }
  }
  .menuItem {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // border-right: 1px solid #e8e8e8;
    width: functions.vw_d(140);

    @include mixins.screen('mobile') {
      width: auto;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    p {
      font-size: functions.vw_d(12);
      color: #2d2d2d;
      opacity: 0.3;

      @include mixins.screen('mobile') {
        font-size: functions.vw(8);
      }
    }
  }

  &.top {
    top: functions.vw_d(63);
  }
}

.fullMenu {
  visibility: visible;
  opacity: 1;

  @include mixins.screen('mobile') {
    opacity: 0;
    visibility: hidden;
  }
}

.minifyMenuMobile {
  position: fixed;
  top: functions.vw_d(20);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: functions.vw_d(116);
  width: 100%;
  padding: 0 functions.vw_d(190);
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #e8e8e8;

  @include mixins.screen('mobile') {
    visibility: visible;
    opacity: 1;
    top: functions.vw(60);
    height: functions.vw(76);
    padding-left: functions.vw(10);
    padding-right: functions.vw(10);
  }

  canvas {
    transition: 0.6s;
    max-width: functions.vw_d(35);

    @include mixins.screen('mobile') {
      max-height: functions.vw(27);
      max-width: functions.vw(85);
    }
  }

  p {
    font-size: functions.vw_d(11);
    @include mixins.screen('mobile') {
      font-size: functions.vw(11);
    }
  }
}

.minify {
  transform: translateY(functions.vw_d(75));

  @include mixins.screen('mobile') {
    transform: translateY(functions.vw(-70));
    visibility: hidden;
    opacity: 0;
  }
}

.minMenu {
  position: fixed;
  height: functions.vw_d(116);
  padding-bottom: 0;
  justify-content: center;
  padding-top: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #f3f3f3;

  .menuItem {
    width: functions.vw_d(100);
    margin-right: functions.vw_d(10);
    margin-left: functions.vw_d(10);
  }

  .item {
    border-right: none;
    position: relative;

    &:last-child {
      &:after {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: functions.vw_d(0);
      top: 0;
      bottom: 0;
      width: 1px;
      margin-top: auto;
      margin-bottom: auto;
      height: functions.vw_d(71);
      background-color: #f3f3f3;
    }
  }

  @include mixins.screen('mobile') {
    visibility: visible;
    opacity: 1;
    top: functions.vw(55);
    height: functions.vw(76);
    padding-left: functions.vw(10);
    padding-right: functions.vw(10);
  }

  canvas {
    transition: 0.2s;
    max-width: functions.vw_d(90);

    @include mixins.screen('mobile') {
      max-height: functions.vw(27);
    }
  }

  p {
    font-size: functions.vw_d(11) !important;

    @include mixins.screen('mobile') {
      font-size: functions.vw(11) !important;
    }
  }
  // transform: translateY(vw_d(-70));
}

canvas {
  transition: 0.6s;
  max-width: functions.vw_d(82);
}

.hidden {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transform: translateY(functions.vw_d(-50));
}
