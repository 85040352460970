@use 'src/styles/mixins';
@use 'src/styles/functions';

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: functions.vw_d(143);
  padding-bottom: functions.vw_d(100);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @include mixins.screen('mobile') {
    padding-top: functions.vw(114);
    padding-bottom: functions.vw(44);
  }

  &.blackContent {
    .info {
      h1 {
        color: #000;
      }
      .subtitleWrapper {
        .subtitle {
          color: #000;
        }
        .CTAButton {
          color: rgba(0, 169, 125, 1);
          border: 1px solid rgba(0, 169, 125, 1);
          &:hover {
            color: #fff;
            background-color: rgba(0, 169, 125, 1);
          }
        }
      }
    }
  }

  .info {
    position: relative;
    width: functions.vw_d(1120);
    @include mixins.screen('mobile') {
      width: functions.vw(320);
      text-align: center;
    }
    h1 {
      padding-bottom: functions.vw_d(50);
      font-size: functions.vw_d(94);
      color: rgba(255, 255, 255, 0.99);
      text-align: left;
      line-height: 1.277;
      font-weight: 600;
      @include mixins.screen('mobile') {
        padding-bottom: functions.vw(27);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }

    .subtitleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .subtitle {
        width: functions.vw_d(749);
        font-size: functions.vw_d(22);
        letter-spacing: functions.vw_d(-0.29);
        color: rgb(255, 255, 255);
        text-align: left;
        line-height: 1.455;
        font-weight: 400;

        @include mixins.screen('mobile') {
          width: 100%;
          font-size: functions.vw(16);
          letter-spacing: functions.vw(-0.18);
          line-height: 1.5;
        }
      }
      .CTAButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: functions.vw_d(167);
        height: functions.vw_d(48);
        border-radius: functions.vw_d(24);
        font-size: functions.vw_d(13);
        letter-spacing: -0.05px;
        color: rgb(255, 255, 255);
        line-height: 1.231;
        font-weight: 600;
        border: 1px solid rgb(255, 255, 255);
        transition: all ease 0.2s;
        &:hover {
          color: #000;
          background-color: rgb(255, 255, 255);
        }

        @include mixins.screen('mobile') {
          display: none;
        }
      }
    }
  }
}
