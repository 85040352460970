@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.designContent {
  position: relative;
  padding: 0 0 functions.vw_d(140);
  z-index: 31;
  color: #fff;
  background-color: var(--color-bg-dark);

  h2,
  p {
    color: inherit;
  }

  @include mixins.screen('mobile') {
    padding: functions.vw(40) 0 functions.vw(70);
  }
}

.content {
  width: functions.vw_d(1120);

  @include mixins.screen('mobile') {
    width: functions.vw(320);
  }
}

.tabsBtns {
  position: relative;
  display: flex;
  height: functions.vw_d(48.6);
  margin-bottom: functions.vw_d(44.4);
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: functions.vw_d(24.48);

  @include mixins.screen('mobile') {
    height: functions.vw(40);
    margin-bottom: functions.vw(24);
    border-radius: functions.vw(20);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: rgb(39, 196, 157);
    border-radius: functions.vw_d(24.48);
    z-index: 2;
    transition: all ease 0.5s;

    @include mixins.screen('mobile') {
      border-radius: functions.vw(20);
    }
  }

  &.left {
    &:before {
      transform: translateX(0%);
    }
  }

  &.right {
    &:before {
      transform: translateX(100%);
    }
  }

  .tabBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: functions.vw_d(165.55);
    height: 100%;
    border-radius: functions.vw_d(24.48);
    background-color: transparent;
    font-size: functions.vw_d(22);
    letter-spacing: functions.vw_d(-0.29);
    font-weight: 600;
    transition: all ease 0.5s;
    cursor: pointer;
    z-index: 3;

    @include mixins.screen('mobile') {
      width: functions.vw(98);
      height: 100%;
      border-radius: functions.vw(20);
      font-size: functions.vw(16);
      letter-spacing: functions.vw_d(-0.21);
    }
    &.active {
      color: #fff;
    }
  }
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    width: functions.vw_d(900);
    padding-bottom: functions.vw_d(70);

    @include mixins.screen('mobile') {
      width: 100%;
      padding-bottom: functions.vw(40);
    }
    .title {
      padding-bottom: functions.vw_d(40);
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-1.03);
      line-height: 1.17;
      font-weight: 600;
      text-align: center;

      @include mixins.screen('mobile') {
        padding-bottom: functions.vw(24);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.48);
        line-height: 1.263;
      }
    }

    p {
      font-size: functions.vw_d(26);
      letter-spacing: -0.33px;
      line-height: 1.385;
      font-weight: 400;
      text-align: center;

      @include mixins.screen('mobile') {
        font-size: functions.vw(16);
        letter-spacing: functions.vw(-0.18);
        line-height: 1.5;
      }
    }
  }
}

.videoWrapper {
  position: relative;
  width: functions.vw_d(1120);
  aspect-ratio: 1920 / 1064;

  @include mixins.screen('mobile') {
    width: 100%;
    aspect-ratio: 1920 / 1080;
  }
}

.video video {
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.TabWrapper {
  opacity: 0;
}

.TabWrapperEnter {
  opacity: 1;
  transition: ease-in opacity 200ms;
}
.TabWrapperEnterActive {
  opacity: 1;
}

.TabWrapperEnterDone {
  opacity: 1;
}

.TabWrapperExit {
  transition: ease-in opacity 200ms;
  opacity: 0;
}

.TabWrapperExitActive {
  opacity: 0;
}

.TabWrapperExitDone {
  opacity: 0;
}
