@use 'src/styles/mixins';
@use 'src/styles/functions';

.infoItem {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: functions.vw_d(75) functions.vw_d(130) functions.vw_d(75) functions.vw_d(112);
  border-right: solid 1px #ebebeb;
  border-bottom: solid 1px #ebebeb;

  &:first-child {
    border-top: solid 1px #ebebeb;
    @include mixins.screen('mobile') {
      padding-top: 0;
      border-top: none;
    }
  }

  &:nth-child(2) {
    border-top: solid 1px #ebebeb;
    @include mixins.screen('mobile') {
      border-top: none;
    }
  }

  @include mixins.screen('mobile') {
    width: 100%;
    padding: functions.vw(40) 0;
    border: none;
    border-bottom: solid functions.vw(1) #ebebeb;
  }

  &.whiteText {
    background-color: rgba(15, 15, 15, 1);
    border-color: rgba(33, 33, 33, 1);
    @include mixins.screen('mobile') {
      background-color: rgba(15, 15, 15, 0);
      border-bottom: solid functions.vw(1) rgba(51, 51, 51, 1);
    }
    h3 {
      color: #fff;
    }
    p {
      color: rgba(170, 170, 170, 1);
    }
  }

  h3 {
    $d-font-size: 34;

    @include mixins.screen('non-mobile') {
      font-size: functions.vw_d($d-font-size);
      line-height: 1.25;
    }

    @include mixins.screen('mobile') {
      font-size: functions.vw(26);
      line-height: functions.vw(32);
    }
  }

  p {
    $d-padding-block-start: 21;
    $d-font-size: 20;
    color: #2b2b2b;

    @include mixins.screen('non-mobile') {
      font-size: functions.vw_d($d-font-size);
      line-height: functions.vw_d(24);
      padding-block-start: functions.vw_d($d-padding-block-start);
    }

    @include mixins.screen('mobile') {
      font-size: functions.vw(16);
      line-height: functions.vw(24);
      padding-block-start: functions.vw($d-padding-block-start);
    }
  }
}
