@use 'sass:math';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.container {
  --d-section-item-height: 70vh;

  $d-padding-block-start: 117 + 64;
  width: 100%;

  @include mixins.screen('non-mobile') {
    padding-block-start: functions.vw_d($d-padding-block-start);
  }
}

.tabs {
  @include mixins.screen('non-mobile') {
    grid-template-columns: 40% 60%;
  }
}

.reverse {
  @include mixins.screen('non-mobile') {
    direction: rtl;

    .itemTitle,
    .itemDesc {
      direction: ltr;
    }
  }
}

.tabsNav {
  $d-padding-inline-end: 8;
  $d-padding-block-end: 220;

  @include mixins.screen('non-mobile') {
    padding-inline-end: functions.vw_d($d-padding-inline-end);
    padding-block-end: functions.vw_d($d-padding-block-end);
  }

  @include mixins.screen('mobile') {
    row-gap: functions.vw(60);
  }

  .item {
    transition: opacity 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0.24;

    &.active {
      opacity: 1;
    }

    @include mixins.screen('non-mobile') {
      height: var(--d-section-item-height);
    }
  }
}

.itemTitle,
.itemDesc p {
  color: inherit;
}

.itemTitle {
  $d-font-size: 42;

  @include mixins.screen('non-mobile') {
    font-size: functions.vw_d($d-font-size);
    line-height: 1.25;
  }

  @include mixins.screen('mobile') {
    margin-block-end: functions.vw(14);
    font-size: functions.vw(26);
    line-height: math.div(32, 26);
  }
}

.itemDesc p {
  $d-padding-block-start: 21;
  $d-font-size: 17;

  @include mixins.screen('non-mobile') {
    font-size: functions.vw_d($d-font-size);
    line-height: math.div(24, $d-font-size);
    padding-block-start: functions.vw_d($d-padding-block-start);
  }

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
  }
}

.itemContent {
  .video video {
    background-color: #000;
  }

  @include mixins.screen('mobile') {
    $offset-x: math.div((375 - 320), 2);
    margin-inline-start: -#{functions.vw($offset-x)};
    margin-inline-end: -#{functions.vw($offset-x)};
    margin-block-end: functions.vw(24);

    .video video {
      width: 100vw;
      aspect-ratio: 1;
    }
  }
}

.tabsContent {
  .item {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: var(--d-section-item-height);

    &:not(.active) {
      pointer-events: none;
    }
  }

  .itemContent {
    position: relative;
    width: 100%;
    height: clamp(100px, 85vh, 57vw);
  }

  video {
    height: 100%;
  }

  img,
  .video {
    height: 100%;
    max-width: none;
    aspect-ratio: 1;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;

    position: absolute;
    inset-block-start: 0;

    @include mixins.screen('non-mobile') {
      inset-inline-end: calc((100vw - #{functions.vw_d(1120)}) / -2 + 0px);
    }
  }
}
