@use 'src/styles/mixins';
@use 'src/styles/functions';

.infoSection {
  padding: functions.vw_d(183) functions.vw_d(133) functions.vw_d(12) functions.vw_d(134);

  @include mixins.screen('mobile') {
    padding: functions.vw(50) 0 0 0;
  }

  .mainTitle {
    font-size: functions.vw_d(64);
    line-height: functions.vw_d(72);
    letter-spacing: -0.36px;
    width: functions.vw_d(520);
    margin-bottom: functions.vw_d(45);

    @include mixins.screen('mobile') {
      font-size: functions.vw(26);
      line-height: 1.2;
      letter-spacing: -0.33px;
      width: functions.vw(290);
      margin: 0 auto functions.vw(16);
    }
  }

  .mainText {
    font-size: functions.vw_d(22);
    line-height: 1.48;
    letter-spacing: -0.29px;
    width: functions.vw_d(480);
    margin-bottom: functions.vw_d(125);

    @include mixins.screen('mobile') {
      font-size: functions.vw(16);
      line-height: 1.5;
      letter-spacing: -0.33px;
      width: functions.vw(310);
      margin: 0 auto functions.vw(23);
    }
  }

  .mainImage {
    width: functions.vw_d(806);
    top: functions.vw_d(158);
    left: functions.vw_d(762);

    @include mixins.screen('mobile') {
      position: static;
      width: 100%;
      padding-left: functions.vw(13);
    }
  }

  .infoItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 functions.vw_d(205);

    @include mixins.screen('mobile') {
      display: block;
      padding-top: functions.vw(83);
    }
  }

  .info {
    margin-bottom: functions.vw_d(173);

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(111);
    }

    &.join {
      .infoImage {
        width: functions.vw_d(412);
        margin: functions.vw_d(28) 0 0 functions.vw_d(-39);

        @include mixins.screen('mobile') {
          width: functions.vw(175);
          margin: 0 auto;
        }
      }

      .description {
        margin-top: functions.vw_d(-24);
        padding-right: functions.vw_d(30);

        @include mixins.screen('mobile') {
          width: functions.vw(300);
          margin: functions.vw(30) auto 0;
          padding-right: 0;
        }
      }
    }

    &.publish {
      @include mixins.screen('mobile') {
        margin-bottom: functions.vw(100);
      }

      .infoImage {
        width: functions.vw_d(487);
        margin: functions.vw_d(112) 0 0 functions.vw_d(-25);

        @include mixins.screen('mobile') {
          width: functions.vw(250);
          margin: 0 auto;
          padding-right: functions.vw(9);
        }
      }

      .description {
        padding: functions.vw_d(3) 0 0 functions.vw_d(28);

        @include mixins.screen('mobile') {
          width: functions.vw(282);
          margin: functions.vw(30) auto 0;
          padding: 0;
        }
      }
    }

    &.insights {
      @include mixins.screen('mobile') {
        margin-bottom: functions.vw(99);
      }

      .infoImage {
        width: functions.vw_d(367);
        margin: 0 0 0 functions.vw_d(10);

        @include mixins.screen('mobile') {
          width: functions.vw(258);
          margin: 0 auto;
        }
      }

      .description {
        padding: functions.vw_d(70) 0 0 0;

        @include mixins.screen('mobile') {
          padding: 0;
          width: functions.vw(300);
          margin: functions.vw(30) auto 0;
        }
      }
    }

    &.exportData {
      @include mixins.screen('mobile') {
        margin-bottom: functions.vw(75);
      }

      .infoImage {
        width: functions.vw_d(262);
        margin: 1px 0 0 functions.vw_d(24);

        @include mixins.screen('mobile') {
          margin: 0;
          width: functions.vw(210);
          margin: 0 auto;
        }
      }

      .description {
        padding: functions.vw_d(107) 0 0 functions.vw_d(25);

        @include mixins.screen('mobile') {
          padding: 0;
          width: functions.vw(300);
          margin: functions.vw(39) auto 0;
        }
      }
    }

    .infoTitle {
      font-size: functions.vw_d(32);
      line-height: functions.vw_d(44);
      letter-spacing: -0.35px;
      margin-bottom: functions.vw_d(24);

      @include mixins.screen('mobile') {
        font-size: functions.vw(22);
        line-height: functions.vw(28);
        letter-spacing: -0.29px;
        margin-bottom: functions.vw(15);
      }
    }

    .infoText {
      font-size: functions.vw_d(16);
      line-height: functions.vw_d(24);
      letter-spacing: -0.18px;

      @include mixins.screen('mobile') {
        font-size: functions.vw(16);
        line-height: functions.vw(24);
      }
    }
  }
}
