@use 'src/styles/mixins';
@use 'src/styles/functions';

.flowPage {
  .title {
    font-size: functions.vw_d(64);
    width: functions.vw_d(949);
    line-height: normal;

    @include mixins.screen('mobile') {
      font-size: functions.vw(26);
      width: 100%;
    }
  }
  .mainImage {
    height: functions.vw_d(439);
    margin-top: functions.vw_d(77);
    @include mixins.screen('mobile') {
      width: 85%;
      margin-bottom: functions.vw(24);
      height: auto;
    }
  }

  .scrollSectionContent {
    width: functions.vw_d(1120);
    padding-bottom: functions.vw_d(100);
    color: #000;

    @include mixins.screen('mobile') {
      width: functions.vw(320);
      margin-top: 0px;
      padding-bottom: 0;
    }

    h2 {
      width: functions.vw_d(1077);
      padding-bottom: functions.vw_d(36);
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-1);
      line-height: 1.17;
      font-weight: 600;
      text-align: center;

      @include mixins.screen('mobile') {
        width: 100%;
        padding-bottom: functions.vw(24);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }
    .subtitle {
      width: functions.vw_d(900);
      font-size: functions.vw_d(26);
      letter-spacing: functions.vw_d(-0.33);
      line-height: 1.385;
      font-weight: 400;
      text-align: center;

      @include mixins.screen('mobile') {
        width: 100%;
        font-size: functions.vw(16);
        letter-spacing: functions.vw(-0.18);
        line-height: 1.5;
        margin-block-end: functions.vw(70);
      }
    }
  }

  .textLeftRight {
    margin-top: 0;
  }
}
