@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.footer {
  background-color: rgba(0, 0, 0, 0.6);
}

.s1 {
  padding: functions.vw_d(64) functions.vw_d(124) functions.vw_d(58) functions.vw_d(133);
  border-bottom: 1px solid #d8d8d8;
  gap: functions.vw_d(46);

  @include mixins.screen('mobile') {
    display: block;
    padding: functions.vw(51) functions.vw(28) functions.vw(49);
  }

  &Left {
    .title {
      font-size: functions.vw_d(32);
      line-height: functions.vw_d(44);
      letter-spacing: -0.35px;
      padding-bottom: functions.vw_d(24);

      @include mixins.screen('mobile') {
        font-size: functions.vw(26);
        line-height: functions.vw(32);
        letter-spacing: -0.33px;
        padding-bottom: functions.vw(24);
      }
    }
  }

  &Right {
    padding-top: functions.vw_d(4);

    .text {
      margin-bottom: functions.vw_d(30);

      @include mixins.screen('mobile') {
        font-size: functions.vw(16);
        line-height: functions.vw(24);
        letter-spacing: -0.18px;
        margin-bottom: functions.vw(34);
      }
    }

    .CTAButton {
      display: inline-block;
      padding: functions.vw_d(15) functions.vw_d(60);
      font-size: functions.vw_d(13);
      background-color: themes.$green-primary;
      transition: 0.4s;
      &:hover {
        filter: brightness(0.9);
      }

      @include mixins.screen('mobile') {
        font-size: functions.vw(13);
        padding: functions.vw(15) functions.vw(33);
      }
    }
  }
}

.s2 {
  padding: functions.vw_d(73) functions.vw_d(135) functions.vw_d(40);

  @include mixins.screen('mobile') {
    display: block;
    padding: functions.vw(51) 0 functions.vw(27);
  }

  .logo {
    display: inline-block;
    width: functions.vw_d(160);
    margin-top: functions.vw_d(-18);
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    @include mixins.screen('mobile') {
      width: functions.vw(194);
      margin: 0;
      padding: 0 0 functions.vw(43) functions.vw(34);
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 100%;
    }
  }

  .footerLinks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: functions.vw_d(5) functions.vw_d(65);
    margin-left: functions.vw_d(-73);

    @include mixins.screen('mobile') {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      margin: 0;
      padding: 0 functions.vw(28) functions.vw(22);
    }

    .link {
      display: inline-block;
      width: 100%;
      font-size: functions.vw_d(16);
      line-height: 1.6;
      letter-spacing: -0.18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      transition: color 0.25s ease-in-out;

      @include mixins.screen('mobile') {
        font-size: functions.vw(22);
        line-height: functions.vw(50);
        letter-spacing: -0.29px;
      }

      &:nth-child(3n + 2) {
        padding-left: 3px;

        @include mixins.screen('mobile') {
          order: 1;
          padding-left: 0;
        }
      }

      &:nth-child(3n) {
        padding-left: functions.vw_d(39);

        @include mixins.screen('mobile') {
          padding-left: 0;
          order: 2;
        }
      }

      &.last {
        @include mixins.screen('mobile') {
          order: 3;
        }
      }

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .socials {
    width: functions.vw_d(160);

    @include mixins.screen('mobile') {
      width: 100%;
      margin-top: 0;
      padding: functions.vw(48) functions.vw(28) 0;
      border-top: 1px solid #d8d8d8;
    }

    .socialsInner {
      @include mixins.screen('mobile') {
        width: functions.vw(216);
      }
    }

    img {
      width: functions.vw_d(18);
      transition: 0.4s;
      &:hover {
        filter: brightness(0.7);
      }

      @include mixins.screen('mobile') {
        width: functions.vw(28);
      }
    }

    .facebook {
      width: functions.vw_d(10);

      @include mixins.screen('mobile') {
        width: functions.vw(16);
      }
    }

    .instagram {
      @include mixins.screen('mobile') {
        margin-left: functions.vw(10);
      }
    }
  }
}
.copyright {
  margin-left: functions.vw_d(396);
  color: #afafaf;
  font-size: functions.vw_d(12);
  padding-bottom: functions.vw_d(60);

  @include mixins.screen('mobile') {
    margin-left: functions.vw(28);
    font-size: functions.vw(14);
  }
}
