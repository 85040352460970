@use 'functions';

$media-mobile-max: 767;
$media-tablet-min: 768;
$media-tablet-max: 1024;
$media-desktop-min: $media-tablet-max + 1;
$media-desktop-hd-min: 1920;
$media-desktop-l-min: 1550;
$media-mobile: '(max-width: #{$media-mobile-max}px)';
$media-mobile-landscape: '(max-width: #{$media-mobile-max}px) and (orientation: landscape)';
$media-mobile-portrait: '(max-width: #{$media-mobile-max}px) and (orientation: portrait)';

$media-tablet: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)';
$media-tablet-landscape: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)';
$media-tablet-portrait: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)';

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-desktop-hd: '(min-width: #{$media-desktop-hd-min}px)';
$media-desktop-l: '(min-width: #{$media-desktop-l-min}px)';

$media-non-mobile: '(min-width: #{$media-mobile-max}px)';
$media-non-desktop: '(max-width: #{$media-tablet-max}px)';

$green-primary: #00a87e;
$green-dark: #00855f;
$green-a50: #27c49d;
$white-10: rgba(white, 0.1);
$white-45: rgba(white, 0.45);
$white-70: rgba(white, 0.7);
$header-height-mobile: functions.vw(62);
$d-main-page-container-width: 1120px;

$gray-12: rgba(0, 0, 0, 0.12);
$dark-38: rgba(0, 0, 0, 0.38);
$dark-87: rgba(0, 0, 0, 0.87);
