@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.hostingPageWrapper {
  position: relative;
  background-color: var(--color-bg-dark);
}

.hostingPage {
  position: relative;
  background-color: var(--color-bg-dark);

  .topImage {
    position: relative;
    margin-bottom: functions.vw_d(0);
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: functions.vw_d(350);
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 100%);
      // @include mixins.screen('mobile') {
      //   display: none;
      // }
    }
    @include mixins.screen('mobile') {
      padding: 0 functions.vw(30);
      margin-bottom: functions.vw(20);
    }

    img {
      border-radius: functions.vw_d(56);
      @include mixins.screen('mobile') {
        padding: 0;
        border-radius: functions.vw(16);
      }
    }
  }
}
