@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.create {
  $d-section-offset-y: 100;

  padding: functions.vw_d($d-section-offset-y) 0;
  background-color: var(--color-bg-dark);
  color: #fff;

  h2,
  .subtitle {
    color: inherit;
  }

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0 functions.vw(80);
  }

  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }

    h2 {
      width: functions.vw_d(900);
      padding-bottom: functions.vw_d(40);
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-0.53);
      line-height: 1.277;
      font-weight: 600;

      @include mixins.screen('mobile') {
        width: 100%;
        padding-bottom: functions.vw(24);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }
    .subtitle {
      width: functions.vw_d(640);
      font-size: functions.vw_d(22);
      letter-spacing: functions.vw_d(-0.29);
      line-height: 1.455;
      font-weight: 400;

      @include mixins.screen('mobile') {
        width: 100%;
        font-size: functions.vw(16);
        letter-spacing: functions.vw(-0.18);
        line-height: 1.5;
        margin-block-end: functions.vw(70);
      }
    }
  }
}
