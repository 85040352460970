@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.about {
  position: relative;
  overflow-x: hidden;
  font-size: functions.vw_d(22);

  & > div {
    @include mixins.screen('mobile') {
      padding-top: functions.vw(80);
      padding-bottom: 0;
    }
  }

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
    line-height: 1.4;
  }
}

.pageTitle {
  font-size: functions.vw_d(64);
  line-height: functions.vw_d(72);
  margin-bottom: functions.vw_d(65);

  @include mixins.screen('mobile') {
    font-size: functions.vw(26);
    line-height: 1.4;
  }
}

.textTitle {
  font-size: functions.vw_d(32);
  line-height: functions.vw_d(40);

  @include mixins.screen('mobile') {
    font-size: functions.vw(18);
    line-height: 1.4;
  }
}

.hero {
  display: grid;
  grid-auto-rows: 100%;
  grid-template-columns: minmax(0, 1fr);
  margin-bottom: functions.vw_d(100);
  color: white;

  @include mixins.screen('mobile') {
    margin-left: functions.vw(-28);
    margin-right: functions.vw(-28);
  }

  &Slider,
  p {
    align-self: center;
    justify-self: center;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &Slider {
    width: 100%;
    height: functions.vw_d(440);

    @include mixins.screen('mobile') {
      height: functions.vw(202);
    }
  }

  &Slide {
    width: 100%;
  }

  &Img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
  }

  p {
    z-index: 2;
    width: functions.vw_d(520);
    max-width: 100%;
    position: relative;

    @include mixins.screen('mobile') {
      width: functions.vw(220);
      text-align: center;
    }
  }
}

.company {
  margin-bottom: functions.vw_d(93);

  .s1 {
    grid-column-gap: functions.vw_d(70);
    margin-bottom: functions.vw_d(42);
    grid-template-columns: 45% 55%;

    p {
      margin-bottom: functions.vw_d(37);
    }

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(20);
    }
  }

  .s2 {
    border-radius: 12px;
    padding: functions.vw_d(63) functions.vw_d(75) functions.vw_d(75) functions.vw_d(68);
    background-color: rgba(black, 0.05);
    grid-template-columns: 52% 48%;

    p:first-child {
      color: themes.$green-primary;
      width: functions.vw_d(350);
      max-width: 100%;

      @include mixins.screen('mobile') {
        width: 100%;
        margin-bottom: functions.vw(30);
      }
    }
  }

  @include mixins.screen('mobile') {
    .s1,
    .s2 {
      display: block;
    }
  }
}

.ceo {
  color: white;
  position: relative;
  padding: functions.vw_d(82) 0 functions.vw_d(91) functions.vw_d(13);

  .grid {
    @include mixins.screen('mobile') {
      display: block;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -50vw;
    background-color: themes.$green-primary;
    z-index: -1;
    pointer-events: none;
  }

  .col1 {
    padding-top: functions.vw_d(14);
    padding-right: functions.vw_d(40);

    @include mixins.screen('mobile') {
      display: flex;
      align-items: center;
      margin-bottom: functions.vw(16);
    }
  }

  &Img {
    display: block;
    width: functions.vw_d(211);
    height: functions.vw_d(211);
    margin-bottom: functions.vw_d(29);

    @include mixins.screen('mobile') {
      width: functions.vw(100);
      height: functions.vw(100);
      margin-right: functions.vw(10);
    }
  }

  &Details {
    margin-left: functions.vw_d(30);
    padding-left: functions.vw_d(16);
    border-left: 1px solid white;

    @include mixins.screen('mobile') {
      height: functions.vw(60);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      padding-left: functions.vw(12);
    }
  }

  &Name {
    margin-bottom: functions.vw_d(2);
  }

  &Role {
    color: rgba(white, 0.7);
  }

  &Title {
    margin-bottom: functions.vw_d(40);
  }
}

.team {
  position: relative;
  padding-top: functions.vw_d(80);
  padding-bottom: functions.vw_d(120);

  &::before {
    content: '';
    position: absolute;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -50vw;
    background-color: rgba(black, 0.05);
    z-index: -1;
    pointer-events: none;
  }

  &Title {
    width: functions.vw_d(565);
    max-width: 100%;
    margin-bottom: functions.vw_d(80);

    @include mixins.screen('mobile') {
      width: functions.vw(300);
    }
  }

  &Grid {
    display: flex;
    flex-flow: row wrap;
  }

  &Member {
    flex: 0 0 50%;
    margin-bottom: functions.vw_d(60);
    padding-right: 16px;

    @include mixins.screen('mobile') {
      flex-basis: 100%;
    }
  }
}

.memberPic {
  width: functions.vw_d(142);
  height: functions.vw_d(142);
  margin-right: functions.vw_d(55);
  border-radius: 50%;
  overflow: hidden;

  svg {
    display: block;
    width: functions.vw_d(50);
    height: functions.vw_d(50);
  }
}

.joinUs {
  color: themes.$green-primary;
  transition: 250ms ease;

  &:hover {
    color: themes.$green-dark;
  }

  .memberPic {
    background-color: currentColor;
  }

  .textTitle {
    color: currentColor;
  }
}

.readMore {
  display: inline-block;
  text-decoration: underline;
  margin-top: functions.vw_d(25);

  @include mixins.screen('mobile') {
    margin-top: functions.vw(20);
  }
}
