@use 'src/styles/mixins';
@use 'src/styles/functions';

.embedLoader {
  @include mixins.screen('mobile') {
    padding-top: functions.vw(14);
  }

  @include mixins.screen('tablet') {
    padding-top: functions.vw(40);
  }

  @include mixins.screen('tablet-landscape') {
    padding-top: functions.vw(30);
  }

  @include mixins.screen('tablet-portrait') {
    padding-top: functions.vw(30);
  }

  @include mixins.screen('desktop') {
    padding-top: functions.vw_d(100);
  }
}
