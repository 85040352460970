@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.infoSection {
  $d-section-offset-y: 100;

  padding: functions.vw_d($d-section-offset-y) 0;
  background-color: var(--color-bg-dark);
  color: #fff;

  h2,
  .subtitle {
    color: inherit;
  }

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0 functions.vw(80);
  }

  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }
  }

  .title {
    width: 100%;
    margin-bottom: functions.vw_d(40);
    font-size: functions.vw_d(94);
    line-height: 1.277;
    letter-spacing: functions.vw_d(-0.53);

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(24);
      font-size: functions.vw(38);
      line-height: 1.263;
      letter-spacing: functions.vw(-0.5);
    }
  }

  .subtitle {
    width: functions.vw_d(640);
    margin-bottom: 0;
    font-size: functions.vw_d(22);
    line-height: 1.455;
    letter-spacing: functions.vw_d(-0.29);

    @include mixins.screen('mobile') {
      width: 100%;
      font-size: functions.vw(16);
      line-height: 1.5;
      letter-spacing: functions.vw(-0.18);
      margin-block-end: functions.vw(70);
    }
  }
}
