@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.integartionTools {
  padding: functions.vw_d(100) 0 functions.vw_d(120);
  background-color: var(--color-bg-dark);

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0;
  }

  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }
    .text {
      display: flex;
      justify-content: space-between;
      width: functions.vw_d(1006);
      padding-bottom: functions.vw_d(86);

      @include mixins.screen('mobile') {
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: functions.vw(46);
      }

      .title {
        width: functions.vw_d(468);
        font-size: functions.vw_d(44);
        letter-spacing: functions.vw_d(-0.25);
        line-height: 1.205;
        color: rgba(255, 255, 255, 0.87);
        font-weight: 600;

        @include mixins.screen('mobile') {
          width: 100%;
          padding-bottom: functions.vw(25);
          font-size: functions.vw(26);
          letter-spacing: functions.vw(-0.33);
          line-height: 1.385;
          text-align: center;
        }
      }

      p {
        width: functions.vw_d(434);
        font-size: functions.vw_d(22);
        letter-spacing: functions.vw_d(-0.29);
        line-height: 1.455;
        color: rgba(255, 255, 255, 0.87);

        @include mixins.screen('mobile') {
          width: 100%;
          font-size: functions.vw(16);
          letter-spacing: functions.vw(-0.18);
          line-height: 1.5;
          text-align: center;
        }
      }
    }

    .toolsImages {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: functions.vw_d(70);

      @include mixins.screen('mobile') {
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 0;
        row-gap: functions.vw(30);
      }

      img {
        display: block;

        &:first-child {
          width: functions.vw_d(96);

          @include mixins.screen('mobile') {
            width: functions.vw(67);
            margin-right: functions.vw(32);
          }
        }
        &:nth-child(2) {
          width: functions.vw_d(62);

          @include mixins.screen('mobile') {
            width: functions.vw(43);
            margin-right: functions.vw(33);
          }
        }
        &:nth-child(3) {
          width: functions.vw_d(48);

          @include mixins.screen('mobile') {
            width: functions.vw(31);
          }
        }
        &:nth-child(4) {
          width: functions.vw_d(180);

          @include mixins.screen('mobile') {
            width: functions.vw(126);
            margin-right: functions.vw(45);
          }
        }
        &:last-child {
          width: functions.vw_d(64);

          @include mixins.screen('mobile') {
            width: functions.vw(45);
          }
        }
      }
    }
  }
}
