@use 'src/styles/mixins';
@use 'src/styles/functions';

.pageLayout {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentWrap {
  padding-top: functions.vw_d(155);
  width: functions.vw_d(1115);
  margin: 0 auto;
  flex-grow: 1;

  @include mixins.screen('mobile') {
    width: 100%;
    padding: functions.vw(90) functions.vw(28) functions.vw(75);
  }
}

.fullVideo {
  @include mixins.screen('mobile') {
    padding: functions.vw(90) functions.vw(0) functions.vw(75);
  }
}

.fullWidth {
  padding-top: 0;
  width: 100%;
  @include mixins.screen('mobile') {
    padding: 0;
  }
}
