@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.press-center {
  position: relative;
  overflow-x: hidden;
  font-size: functions.vw_d(22);
  line-height: functions.vw_d(32);

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
    line-height: 1.4;
  }
}

.newsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: functions.vw_d(70);
  padding-right: functions.vw_d(70);
}

.pageTitle {
  font-size: functions.vw_d(64);
  line-height: functions.vw_d(72);
  margin-bottom: functions.vw_d(65);

  @include mixins.screen('mobile') {
    font-size: functions.vw(26);
    line-height: 1.4;
  }
}

.silverBlock {
  display: flex;
  border-radius: 12px;
  padding: functions.vw_d(63) functions.vw_d(75) functions.vw_d(75) functions.vw_d(68);
  background-color: rgba(black, 0.05);
  margin-bottom: functions.vw_d(93);

  @include mixins.screen('mobile') {
    display: block;
    margin-bottom: functions.vw(30);
  }

  .leftCol {
    width: 52%;
    @include mixins.screen('mobile') {
      width: 100%;
      margin-bottom: functions.vw(30);
    }
    p {
      font-size: functions.vw_d(32);
      line-height: functions.vw_d(40);
      font-weight: bold;
      color: themes.$green-primary;
      width: functions.vw_d(350);
      max-width: 100%;

      @include mixins.screen('mobile') {
        width: 100%;
        font-size: functions.vw(18);
        line-height: 1.4;
      }
    }
  }

  .rightCol {
    width: 48%;
    @include mixins.screen('mobile') {
      width: 100%;
    }
    p {
      font-size: functions.vw_d(22);
      line-height: functions.vw_d(32);
      a {
        color: themes.$green-primary;
        text-decoration: underline;
      }
      @include mixins.screen('mobile') {
        font-size: functions.vw(16);
        line-height: 1.4;
      }
    }
  }
}
