@use 'src/styles/mixins';
@use 'src/styles/functions';

.iconsBlock {
  width: functions.vw_d(1120);
  padding-bottom: functions.vw_d(140);

  @include mixins.screen('mobile') {
    width: 100%;
    padding: 0 functions.vw(30) functions.vw(80);
  }

  h3 {
    padding-bottom: functions.vw_d(86);
    font-size: functions.vw_d(34);
    line-height: functions.vw_d(45);
    color: #fff;
    @include mixins.screen('mobile') {
      padding-bottom: 0;
      font-size: functions.vw(31);
      line-height: functions.vw(32);
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 2%;
    grid-row-gap: functions.vw_d(20);

    @include mixins.screen('mobile') {
      display: block;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;
    }

    .item {
      width: 100%;
      padding: functions.vw_d(22) functions.vw_d(67) functions.vw_d(22) functions.vw_d(34);
      border: solid 1px rgba(40, 40, 40, 1);
      align-self: stretch;

      @include mixins.screen('mobile') {
        padding: functions.vw(42) 0 functions.vw(22) 0;
        border: none;
        border-bottom: solid 1px rgba(40, 40, 40, 1);
      }

      img {
        display: block;
        width: functions.vw_d(123);
        height: functions.vw_d(110);
        @include mixins.screen('mobile') {
          width: functions.vw(118);
          height: functions.vw(107);
        }
      }

      h4 {
        padding-bottom: functions.vw_d(6);
        font-size: functions.vw_d(27);
        line-height: functions.vw_d(31);
        color: #fff;
        @include mixins.screen('mobile') {
          padding-bottom: functions.vw(2);
          font-size: functions.vw(20);
          line-height: functions.vw(24);
        }
      }

      p {
        font-size: functions.vw_d(19);
        line-height: functions.vw_d(29);
        color: #fff;
        @include mixins.screen('mobile') {
          font-size: functions.vw(16);
          line-height: functions.vw(20);
        }
      }
    }
  }
}
