@use 'src/styles/mixins';
@use 'src/styles/functions';

.card {
  position: relative;
  padding: functions.vw_d(67) 0 functions.vw_d(80);

  @include mixins.screen('mobile') {
    padding: 0;
  }

  &Inner {
    @include mixins.screen('mobile') {
      display: block;
      padding: functions.vw(40) 0;
    }
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    top: 100%;
    left: -50%;
    height: 1px;
    width: 200vw;
    background: rgba(0, 0, 0, 0.15);
  }

  .desc {
    padding-right: functions.vw_d(25);

    @include mixins.screen('mobile') {
      padding: 0;
      margin-bottom: functions.vw(20);
    }
  }

  h5 {
    margin-bottom: functions.vw_d(22);

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(16);
    }
  }

  p {
    white-space: break-spaces;
  }
}

.gallery {
  padding-right: functions.vw_d(15);
  grid-template-columns: 40px auto 40px;

  @include mixins.screen('mobile') {
    min-width: 100%;
    margin: 0 -#{functions.vw(28)};
    padding: 0;
    grid-template-columns: functions.vw(28) 1fr functions.vw(28);
  }

  .items {
    overflow: hidden;
    display: grid;
    width: functions.vw_d(554);
    height: functions.vw_d(314);

    &:empty {
      background-color: #eeeeee;
    }

    @include mixins.screen('mobile') {
      position: relative;
      height: auto;
      width: auto;
      max-width: 100%;
      padding-bottom: 56.5%;
    }
  }

  .item {
    display: block;
    position: relative;
    grid-column: 1/2;
    grid-row: 1/2;

    background-size: cover;
    background-position: center;

    @include mixins.screen('mobile') {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 150ms ease;
    }

    &.animate::after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .item:not(.active) {
    display: none;
  }
}

.btn {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.38);
  transition: color 0.2s ease;
}

.btn:hover {
  color: rgba(0, 0, 0, 0.87);
}

.btn svg {
  fill: currentColor;
}

.btnPrev svg {
  transform: rotate(90deg);
}

.btnNext svg {
  transform: rotate(-90deg);
}
