@use 'src/styles/mixins';
@use 'src/styles/functions';

.sectionWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: functions.vw_d(100);
  @include mixins.screen('mobile') {
    margin-bottom: functions.vw(100);
  }
}
.sectionContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: functions.vw_d(60);
  @include mixins.screen('mobile') {
    width: 100%;
    padding: 0 functions.vw(30);
  }
}
