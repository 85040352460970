@use 'src/styles/mixins';
@use 'src/styles/functions';

.clients {
  padding: functions.vw_d(100) 0 functions.vw_d(84);

  p {
    padding: 0 functions.vw_d(124);
    font-size: functions.vw_d(64);
    line-height: 1.2;
    width: functions.vw_d(1142);
    max-width: 100%;
    margin-bottom: functions.vw_d(132);
  }

  @include mixins.screen('mobile') {
    padding: functions.vw(46) 0 functions.vw(40);

    p {
      padding: 0 functions.vw(38);
      font-size: functions.vw(22);
      margin-bottom: functions.vw(65);
      line-height: 1.4;
      width: 100%;
    }
  }
}

.list {
  animation: scroll 45s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes scroll {
  to {
    transform: translateX(-50%);
  }
}

.listItems {
  min-width: 100vw;
  grid-auto-flow: column;
  column-gap: functions.vw_d(60);
  margin-left: functions.vw_d(60);

  // img {
  //   margin-right: vw_d(40);
  // }

  @include mixins.screen('mobile') {
    min-width: unset;
    column-gap: functions.vw(40);
    margin-left: functions.vw(40);

    img {
      // margin-right: vw(40);
      max-height: functions.vw(34);
    }
  }
}
