@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.logic {
  position: relative;
  margin-bottom: functions.vw_d(180);

  @include mixins.screen('mobile') {
    padding-bottom: functions.vw(40);

    &::before,
    &::after {
      content: '';
      display: block;
      font-size: 0;
      height: 1px;
      position: absolute;
      left: -50%;
      width: 200vw;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &::after {
      top: 100%;
    }
  }
}

.title {
  margin-bottom: functions.vw_d(55);
  position: relative;
  z-index: 1;

  @include mixins.screen('mobile') {
    display: none;
  }
}

.content {
  @include mixins.screen('mobile') {
    display: block;
  }
}

.menu {
  @include mixins.screen('mobile') {
    padding-top: functions.vw(16);
  }
}

.item {
  height: functions.vw_d(60);
  padding-top: functions.vw_d(14);
  padding-left: functions.vw_d(3);
  overflow: hidden;
  transition: height 200ms ease, color 200ms ease;

  @include mixins.screen('mobile') {
    height: functions.vw(64);
    padding-top: functions.vw(16);
    padding-left: functions.vw(3);

    [data-content='accordion'] {
      padding-bottom: functions.vw(16);
    }

    &:first-child p {
      margin-bottom: 0;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  h4 {
    @include mixins.screen('mobile') {
      font-size: functions.vw(22);
      line-height: 1.45;
    }
  }

  p {
    padding-top: functions.vw_d(15);
    padding-right: functions.vw_d(30);
    padding-bottom: functions.vw_d(15);
    cursor: default;
    opacity: 0;
    transition: 100ms ease-in-out;
    transform: translateY(1em);

    @include mixins.screen('mobile') {
      padding: functions.vw(16) 0 0;
      margin-bottom: functions.vw(16);
    }
  }

  img {
    @include mixins.screen('mobile') {
      width: functions.vw(320);
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &:not(.active) {
    cursor: pointer;
  }

  &.active {
    p {
      opacity: 1;
      transform: translateY(0);
    }

    h4 {
      color: themes.$green-primary;
    }
  }
}

.images {
  position: relative;

  @include mixins.screen('mobile') {
    display: none;
  }
}

.pic {
  position: absolute;
  top: 0;
  right: 0;
  width: functions.vw_d(700);
  height: auto;
  max-height: none;
  left: functions.vw_d(86);
  display: none;

  &:first-child {
    top: functions.vw_d(-80);
  }
}
