@use 'sass:math';
@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.portfolio {
  padding-top: functions.vw_d(78);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(50);
  }
}

.portfolioHeader {
  padding: 0 functions.vw_d(30);

  h3 {
    font-size: functions.vw_d(64);
    margin: 0 0 functions.vw_d(12);
  }

  p {
    font-size: functions.vw_d(22);
    margin: 0 auto;
    width: functions.vw_d(600);
    max-width: 100%;
  }

  .inspireButton {
    height: functions.vw_d(58);
    width: functions.vw_d(174);
    margin: functions.vw_d(28) auto functions.vw_d(15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: functions.vw_d(16);
    font-weight: 600;
    letter-spacing: functions.vw_d(-0.18);
    color: rgba(0, 0, 0, 0.87);
    border: solid 1px rgba(0, 0, 0, 0.38);
    border-radius: functions.vw_d(29);
    transition: all ease 0.2s;

    &:hover {
      color: #000;
      border: solid 1px #00a87e;
    }
  }

  @include mixins.screen('mobile') {
    h3 {
      font-size: functions.vw(26);
      margin-bottom: functions.vw(8);
    }

    p {
      font-size: functions.vw(16);
      width: 100%;
      max-width: 320px;
    }

    .inspireButton {
      height: functions.vw(48);
      width: functions.vw(140);
      margin: functions.vw(13) auto functions.vw(1);
      font-size: functions.vw(13);
      letter-spacing: functions.vw(-0.18);
      font-weight: 600;
      background-color: rgb(0, 168, 126);
      color: rgb(255, 255, 255);
      border: none;
      border-radius: functions.vw(24);
      transition: all ease 0.2s;
      &:hover {
        color: rgb(255, 255, 255);
      }
    }
  }
}

.portfolioItems {
  position: relative;
  width: 170%;
  left: -35%;
  padding-top: functions.vw_d(40);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(24);
  }
}

.portfolioItem {
  flex: 0 0 auto;
  padding: functions.vw_d(20);

  @include mixins.screen('mobile') {
    padding: functions.vw(12);

    &:nth-child(6) > .portfolioItemTall {
      background-position-x: 0;
    }
  }

  $desktop-item-height: 400;
  $desktop-item-width-wide: 640;
  $desktop-item-width-tall: 224;

  $mobile-item-height: 227;
  $mobile-item-width-wide: 360;
  $mobile-item-width-tall: 125;

  &Wide,
  &Tall {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    transition: 200ms ease-out;

    &:hover {
      @include mixins.screen('desktop') {
        transform: translateY(-10px) scale(1.025);
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
      }

      a {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &Wide {
    background-color: silver;
    width: functions.vw_d($desktop-item-width-wide);
    padding-bottom: math.div($desktop-item-height, $desktop-item-width-wide) * 100%;

    @include mixins.screen('mobile') {
      width: functions.vw($mobile-item-width-wide);
      padding-bottom: math.div($mobile-item-height, $mobile-item-width-wide) * 100%;
    }
  }

  &Tall {
    background-color: silver;
    width: functions.vw_d($desktop-item-width-tall);
    padding-bottom: math.div($desktop-item-height, $desktop-item-width-tall) * 100%;

    @include mixins.screen('mobile') {
      width: functions.vw($mobile-item-width-tall);
      padding-bottom: math.div($mobile-item-height, $mobile-item-width-tall) * 100%;
    }
  }

  a {
    position: absolute;
    top: -20%;
    left: -20%;
    right: -20%;
    bottom: -20%;
    transition: 200ms ease;
    opacity: 0;
    transform: scale(0.9) translate(-5%, 5%);
    background-color: rgba(themes.$green-primary, 0.6);
  }
}
