@use 'src/styles/mixins';
@use 'src/styles/functions';

.content {
  width: functions.vw_d(1120);
  margin: functions.vw_d(107) auto functions.vw_d(107);
  @include mixins.screen('mobile') {
    width: 100%;
    padding: 0 functions.vw(30);
    margin: functions.vw(48) 0 functions.vw(100);
  }

  &.whiteContent {
    .subTitle {
      color: #fff;
    }

    .description {
      color: #fff;
    }
  }

  .subTitle {
    font-size: functions.vw_d(30);
    line-height: 1.3;
    width: functions.vw_d(469);
    @include mixins.screen('mobile') {
      width: auto;
      font-size: functions.vw(22);
      text-align: left;
      margin-bottom: functions.vw(24);
    }
  }

  .description {
    font-size: functions.vw_d(22);
    padding-left: functions.vw_d(12);
    @include mixins.screen('mobile') {
      padding: 0;
      font-size: functions.vw(16);
    }
  }
}
