@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.personalize {
  color: themes.$dark-87;
  padding-block: functions.vw_d(100) functions.vw_d(100);

  h2,
  .subtitle {
    color: inherit;
  }

  @include mixins.screen('mobile') {
    padding-block: functions.vw(80) functions.vw(80);
  }
  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }

    h2 {
      width: functions.vw_d(1077);
      padding-bottom: functions.vw_d(36);
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-1);
      line-height: 1.17;
      font-weight: 600;
      text-align: center;

      @include mixins.screen('mobile') {
        width: 100%;
        padding-bottom: functions.vw(24);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }
    .subtitle {
      width: functions.vw_d(900);
      font-size: functions.vw_d(26);
      letter-spacing: functions.vw_d(-0.33);
      line-height: 1.385;
      font-weight: 400;
      text-align: center;

      @include mixins.screen('mobile') {
        width: 100%;
        font-size: functions.vw(16);
        letter-spacing: functions.vw(-0.18);
        line-height: 1.5;
        margin-block-end: functions.vw(70);
      }
    }
  }
}
