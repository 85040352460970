@use 'src/styles/mixins';
@use 'src/styles/functions';

.page {
  position: relative;
  overflow-x: hidden;
}

.pageContent {
  padding-top: functions.vw_d(310);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(150);
  }
}

.heroImg {
  margin: 0 auto functions.vw_d(54);
  width: 75.5vw;
  max-width: 100%;

  @include mixins.screen('mobile') {
    position: relative;
    left: functions.vw(-23);
    width: functions.vw(370);
    max-width: none;
    margin: 0 auto functions.vw(28);
  }
}

.heroTitle {
  margin-bottom: functions.vw_d(70);
  padding-right: functions.vw_d(40);
  color: rgba(0, 0, 0, 0.87);

  @include mixins.screen('mobile') {
    margin-bottom: functions.vw(28);
    padding: 0;
  }
}

.heroInfo {
  margin-bottom: functions.vw_d(194);

  h3 {
    padding-right: functions.vw_d(110);
  }

  @include mixins.screen('mobile') {
    display: block;
    margin-bottom: functions.vw(60);

    h3 {
      margin-bottom: functions.vw(16);
      padding-right: 0;
    }
  }
}

.cards {
  .title {
    padding-right: functions.vw_d(160);

    @include mixins.screen('mobile') {
      padding: 0;
      margin-bottom: functions.vw(16);
    }
  }
}
