@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.solutions {
  padding: functions.vw_d(86) 0 functions.vw_d(114);
  background-color: var(--color-bg-dark);

  @include mixins.screen('mobile') {
    padding: functions.vw(80) 0;
  }

  .content {
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: functions.vw(320);
    }

    .title {
      font-size: functions.vw_d(94);
      letter-spacing: functions.vw_d(-0.53);
      line-height: 1.277;
      padding-bottom: functions.vw_d(90);
      color: #fff;

      @include mixins.screen('mobile') {
        padding-bottom: functions.vw(73);
        font-size: functions.vw(38);
        letter-spacing: functions.vw(-0.5);
        line-height: 1.263;
      }
    }

    .items {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include mixins.screen('mobile') {
        flex-wrap: wrap;
      }

      .contentItem {
        width: functions.vw_d(299.31);

        @include mixins.screen('mobile') {
          padding-bottom: functions.vw(60);
          width: functions.vw(300);

          &:last-child {
            padding-bottom: 0;
          }
        }

        h4 {
          height: auto;
          min-height: functions.vw_d(88);
          margin-bottom: functions.vw_d(31);
          font-size: functions.vw_d(32);
          letter-spacing: functions.vw_d(-0.35);
          line-height: 1.375;
          color: #fff;
          font-weight: 600;

          @include mixins.screen('mobile') {
            min-height: inherit;
            margin-bottom: functions.vw(14);
            font-size: functions.vw(26);
            letter-spacing: functions.vw(-0.28);
            line-height: 1.385;
          }
        }
        p {
          display: -webkit-box;
          line-clamp: 4;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          font-size: functions.vw_d(16);
          letter-spacing: functions.vw_d(-0.18);
          line-height: 1.5;
          color: #fff;
          font-weight: 400;

          @include mixins.screen('mobile') {
            font-size: functions.vw(16);
            letter-spacing: functions.vw(-0.18);
            line-height: 1.5;
          }
        }

        .readMore {
          display: block;
          padding-top: functions.vw_d(17);
          font-size: functions.vw_d(16);
          letter-spacing: functions.vw_d(-0.18);
          line-height: 1.25;
          color: rgb(39, 196, 157);
          font-weight: 600;

          @include mixins.screen('mobile') {
            padding-top: functions.vw(15);
            font-size: functions.vw(16);
            letter-spacing: functions.vw(-0.18);
            line-height: 1.25;
          }
        }
      }
    }
  }
}
