@use 'src/styles/mixins';
@use 'src/styles/functions';

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    width: functions.vw_d(1120);

    @include mixins.screen('mobile') {
      width: 100%;
      padding: 0 functions.vw(30);
    }
  }
}
