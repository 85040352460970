@use 'src/styles/mixins';
@use 'src/styles/functions';

.page {
  font-size: functions.vw_d(22);
  line-height: 1.45;

  @include mixins.screen('mobile') {
    font-size: functions.vw(16);
  }
}

.pageContent {
  padding-top: functions.vw_d(86);

  @include mixins.screen('mobile') {
    padding-top: functions.vw(100);
  }
}

.hero {
  img {
    display: block;
    margin: 0 auto;
    width: functions.vw_d(490);
    max-width: 100%;

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(8);
    }
  }

  h1 {
    width: functions.vw_d(830);
    margin: 0 auto functions.vw_d(30);
    font-size: functions.vw_d(64);
    line-height: 1.1;

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(20);
      font-size: functions.vw(26);
      line-height: 1.3;
    }
  }

  p {
    margin: 0 auto functions.vw_d(110);
    width: functions.vw_d(860);
    max-width: 100%;

    @include mixins.screen('mobile') {
      margin-bottom: functions.vw(64);
    }
  }

  @include mixins.screen('mobile') {
    img,
    h1,
    p {
      width: 100%;
    }
  }
}

.grid {
  overflow: hidden;
  position: relative;
  margin-bottom: functions.vw_d(120);

  @include mixins.screen('mobile') {
    display: block;
    margin-bottom: 0;
  }
}

.gridItem {
  padding: functions.vw_d(70) 0;

  svg {
    display: block;
    height: auto;
    width: functions.vw_d(44);
    margin-bottom: functions.vw_d(32);
  }

  h2 {
    font-size: functions.vw_d(32);
    margin-bottom: functions.vw_d(34);

    @include mixins.screen('mobile') {
      font-size: functions.vw(22);
      white-space: break-spaces;
    }
  }

  &:nth-child(even) {
    padding-left: functions.vw_d(95);
  }

  &:nth-child(odd) {
    position: relative;
    padding-right: functions.vw_d(95);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.15);
    }

    &::before {
      top: 0;
      left: 0;
      width: 100vw;
      height: 1px;
    }

    &::after {
      top: functions.vw_d(70);
      bottom: functions.vw_d(70);
      right: 0;
      width: 1px;
    }
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  @include mixins.screen('mobile') {
    &:not(:last-child) {
      margin-bottom: functions.vw(48);
    }

    &:nth-child(n) {
      padding: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;

      svg {
        width: functions.vw(24);
      }

      h2 {
        margin-bottom: functions.vw(16);
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
