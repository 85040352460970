@use 'src/styles/mixins';
@use 'src/styles/functions';

.textBlock {
  width: functions.vw_d(1120);
  padding-bottom: functions.vw_d(100);

  @include mixins.screen('mobile') {
    width: 100%;
    padding: 0 functions.vw(30) functions.vw(40);
  }

  h3 {
    width: functions.vw_d(500);
    font-size: functions.vw_d(34);
    line-height: functions.vw_d(45);
    color: #fff;
    @include mixins.screen('mobile') {
      width: 100%;
      font-size: functions.vw(20);
      line-height: functions.vw(24);
    }
  }

  .texts {
    .textItem {
      padding-top: functions.vw_d(34);
      @include mixins.screen('mobile') {
        padding-top: functions.vw(16);
      }
      h4 {
        padding-bottom: functions.vw_d(6);
        font-size: functions.vw_d(22);
        line-height: functions.vw_d(38);
        color: #fff;
        @include mixins.screen('mobile') {
          padding-bottom: functions.vw(3);
          font-size: functions.vw(16);
          line-height: functions.vw(20);
        }
      }
      p {
        font-size: functions.vw_d(19);
        line-height: functions.vw_d(30);
        color: rgba(191, 191, 191, 1);
        @include mixins.screen('mobile') {
          font-size: functions.vw(16);
          line-height: functions.vw(20);
        }
      }
    }
  }
}
