.icon {
	display: inline-block;
	vertical-align: middle;
}

.svg {
	display: block;

	&:not([width]) {
		width: 100%;
	}
}
