@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

html.disableScroll {
  overflow-y: hidden !important;
}

.mobileLogo {
  position: relative;

  img {
    height: functions.vw(40);
  }
}

.header {
  top: 0;
  left: 0;
  height: functions.vw_d(90);
  padding: 0 functions.vw_d(31);
  transition: all 0.3s;
  background-color: #000;
  box-shadow: 0 functions.vw_d(2) functions.vw_d(5) rgba($color: #000000, $alpha: 0.16);

  &.openedMobileMenu {
    backdrop-filter: none;
  }

  &.whiteHeader {
    background-color: #fff;
  }

  &.minimal {
    height: functions.vw_d(64);
    background-color: #fff;
  }

  @include mixins.screen('mobile') {
    background-color: #000;
    position: fixed;
    padding: functions.vw(12) functions.vw(16) functions.vw(10) functions.vw(23);
    height: themes.$header-height-mobile;
    box-shadow: none;
  }

  .invisible {
    opacity: 0;
    visibility: hidden;
  }

  .menu {
    transform: translateX(functions.vw_d(222));
    font-size: functions.vw_d(13);
    transition: all 0.3s;

    .logoNoText {
      height: functions.vw_d(38);
      margin-right: functions.vw_d(92);
    }

    .navLink {
      margin-right: functions.vw_d(31);
      font-size: functions.vw_d(14);
      transition: 200ms ease;

      &.whiteText {
        color: #fff;
      }
      &.blackText {
        color: #000;
      }
      &:hover,
      &Active {
        &,
        & + svg {
          color: themes.$green-primary !important;
        }
      }
    }
  }

  .bigLogo {
    position: absolute;
    width: functions.vw_d(180);
    top: functions.vw_d(-19);
    left: functions.vw_d(-15);
    transition: all ease 0.3s;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    .logo {
      width: 100%;
      transition: 200ms;

      @include mixins.screen('mobile') {
        width: auto;
        height: functions.vw(40);
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .smallLogo {
    position: absolute;
    top: functions.vw_d(-5);
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .logoNoText {
      width: functions.vw_d(26);
    }
  }

  .createButton,
  .loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: functions.vw_d(40);
    padding: 0 functions.vw_d(32);
    font-size: functions.vw_d(13);
    line-height: functions.vw_d(40);
    font-weight: 400;
    transition: 200ms ease;

    @include mixins.screen('mobile') {
      padding: 0 functions.vw(32);
      font-size: functions.vw(13);
      height: functions.vw(40);
      line-height: functions.vw(40);
    }
  }

  .loginButton {
    color: #00855f;
    // border: solid #000 1px;
    margin-right: functions.vw_d(17);

    &:hover {
      background-color: rgb(255, 255, 255);
      color: #00855f !important;
    }

    &.whiteBorder {
      border: solid #ffffff 1px;
    }
  }

  .loginButtonMobile {
    background: #fff;
    position: relative;
    z-index: 15;
  }

  .startButtonMobile {
    position: relative;
    font-weight: bold;
    z-index: 15;
  }

  .createButton {
    background-color: themes.$green-primary;
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.mobileMenu {
  padding: functions.vw(98) functions.vw(20) functions.vw(15) functions.vw(66);
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(2px) saturate(0.5%);
  transition: 250ms;
  z-index: 32;
  visibility: hidden;
  opacity: 0;
  min-height: 100vh;

  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    padding-bottom: 150px;
  }

  .navLink {
    width: 100%;
    font-size: functions.vw(22);
    letter-spacing: -0.29px;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: functions.vw(16);
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.9);
    visibility: visible;
    opacity: 1;
  }
}

.hamburgerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .hamburgerButton {
    padding: 0;
    margin-left: functions.vw(18);
    position: relative;
    width: functions.vw(27);
    z-index: 11;

    .menuDecorLines {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: 1px;
      background-color: black;

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: black;
      }

      &:after {
        bottom: -10px;
      }

      &:before {
        top: -10px;
      }
    }

    img {
      height: functions.vw(21);
    }
  }
}

.closeIcon {
  width: functions.vw(27);
  height: functions.vw(27);
  position: relative;
  display: block;

  .line {
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: center;
    transform: rotate(45deg);

    &:last-child {
      transform: rotate(-45deg);
    }
  }
}
