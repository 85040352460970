@use 'src/styles/vars';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.editorPage {
  position: relative;
  background-color: var(--color-bg-dark);

  .videoSection {
    width: functions.vw_d(1120);
    margin: 0 auto;
    padding-bottom: 0px;
    @include mixins.screen('mobile') {
      width: 100%;
      padding-bottom: functions.vw(59);
    }
    .videoWrapper {
      position: relative;
      width: 100%;
      height: functions.vw_d(623);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: functions.vw_d(8);
      overflow: hidden;
      @include mixins.screen('mobile') {
        height: functions.vw(177);
        border-radius: functions.vw(8);
      }
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .text {
      width: functions.vw_db(1054);
      margin: 0 auto;
      padding-top: functions.vw_db(118);
      @include mixins.screen('mobile') {
        width: 100%;
        padding-top: functions.vw(48);
      }
      p {
        color: #fff;
        text-align: center;

        b {
          font-weight: 600;
          @include mixins.screen('mobile') {
            display: block;
            padding-bottom: functions.vw(30);
          }
        }
      }
    }
  }

  .scrollSectionContent {
    width: functions.vw_d(1120);
    padding-bottom: functions.vw_d(160);
    color: #fff;

    @include mixins.screen('mobile') {
      width: functions.vw(320);
      margin-top: 0px;
      padding-bottom: functions.vw(150);
    }
  }
}

.info {
  padding: 0 0 functions.vw_d(70);

  @include mixins.screen('mobile') {
    padding: 0;
  }
}
