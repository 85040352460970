@use 'src/styles/themes';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.pageNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;

  .svg {
    width: 100%;
    height: 100%;
    position: relative;
    left: functions.vw_d(-36);

    @include mixins.screen('mobile') {
      left: functions.vw(-18);
    }
  }

  h1 {
    font-size: functions.vw_d(35);
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;

    @include mixins.screen('mobile') {
      font-size: functions.vw(35);
    }
  }

  .homePageButton {
    position: absolute;
    bottom: functions.vw_d(40);
    padding: 0 functions.vw_d(32);
    height: functions.vw_d(40);
    font-size: functions.vw_d(14);
    background-color: themes.$green-primary;
    border-radius: 100px;
    color: #fff;
    font-weight: 600;

    @include mixins.screen('mobile') {
      bottom: functions.vw(40);
      padding: 0 functions.vw(32);
      font-size: functions.vw(16);
      height: functions.vw(48);
    }
  }
}
